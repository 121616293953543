import { InjectionToken } from '@angular/core';
import { MonitoringService } from './services/monitoring.service';

export interface AzureInsightsConfig {
  instrumentationKey: string;
}

export const AZURE_INSIGHTS_CONFIG = new InjectionToken<AzureInsightsConfig>('AZURE_INSIGHTS_CONFIG');

export const MONITORING_SERVICE = new InjectionToken<MonitoringService>('MONITORING_SERVICE');
