import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Chat, Citation, NewChatSuggestionType } from '@iese-chatbot/common-utils';

// TODO refactor de props para, en la medida de lo posible, usar `id: string` en lugar de `chat: Chat` como prop y
//  buscar dentro de las actions si hiciera falta. Hay que revisar también los effects para ver en qué casos esto es
//  viable y en cuáles no
export const ChatDataActions = createActionGroup({
  source: '[CHAT DATA]',
  events: {
    'Fetch Chat History': emptyProps(),
    'Fetch Success': props<{ chats: Chat[] }>(),
    'Fetch Error': props<{ error: string }>(),

    'Persist Chat History': props<{ chat: Chat }>(),
    'Persist Chat History Success': emptyProps(),
    'Persist Chat History Error': props<{ error: string }>(),

    'Select Chat': props<{ id: string | null }>(),
    'Add Chat': props<{ chat: Chat, mock: boolean, shortcut: NewChatSuggestionType | null }>(),
    'Delete Chat': props<{ id: string }>(),

    'Start Renaming Chat': props<{ chat: Chat, auto: boolean }>(),
    'Confirm Renaming Chat': props<{ chat: Chat, title: string }>(),
    'Cancel Renaming Chat': emptyProps(),

    'Add Message': props<{ chat: Chat, mock: boolean }>(),

    'Start Message Stream': props<{ chat: Chat, mock: boolean, shortcut: NewChatSuggestionType | null }>(),
    'Receive Stream Chunk': props<{ chat: Chat, chunk: string, citations: Citation[] }>(),
    'Complete Message Stream': props<{ chat: Chat, manually: boolean }>(),
    'Stream Error': props<{ error: string }>(),
  },
});
