import { FormControl, FormGroup, Validators } from '@angular/forms';

export const DEFAULT_TRANSLATIONS_CONTENT = '';
export const DEFAULT_TRANSLATIONS_LANGUAGE = 'english';

export type TranslationsOptions = {
  content: string;
  language: string;
}

export class TranslationsForm extends FormGroup {

  constructor(initialValue?: Partial<TranslationsOptions>) {
    super({
      content: new FormControl<string>(
        initialValue?.content ?? DEFAULT_TRANSLATIONS_CONTENT, [Validators.required],
      ),
      language: new FormControl<string>(
        initialValue?.language ?? DEFAULT_TRANSLATIONS_LANGUAGE, [Validators.required],
      ),
    });
  }

}
