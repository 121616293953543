import { createReducer, on } from '@ngrx/store';
import { initialAuthState } from '../state/auth.state';
import { AuthActions } from '../actions/auth.actions';

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.storeAuthData, (state, { account }) => ({
    ...state,
    account,
  })),
);
