<a (click)="sidebarHideMobile()" [routerLink]="['', 'chat']" class="iese-button primary" style="width: auto; height: 100%;" [bdcWalkTriggerFor]="popupStep4">
  <span class="pi pi-pen-to-square"></span>
  <span>New chat</span>
</a>

<bdc-walk-popup sideNoteText="4/10" [showCloseButton]="false" #popupStep4 name="popupStep4" header="Open a new chat" xPosition="after" [offsetX]="-25" [showButton]="true" buttonText="Next" [mustCompleted]="{popupStep3: true}" class="warning">
  <div>
    Initiate a conversation with MyAI by clicking on the "New chat" button. This allows you to start fresh with new queries and tasks.
  </div>
  <div class="buttonsSkip">
    <button class="iese-button iese-button-skip" (click)="this.bdcWalkService.setTaskCompleted('popupStep3', false)" mat-raised-button color="primary">Previous</button>
    <button class="iese-button iese-button-skip" (click)="endOnboardingTour()" mat-raised-button color="primary">Skip</button>
  </div>
</bdc-walk-popup>
