import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Chat,
  generateUUID,
  MarkdownParsePipe,
  NewChatSuggestion,
  NewChatSuggestionType,
  Role,
} from '@iese-chatbot/common-utils';
import { DialogModule } from 'primeng/dialog';
import { TeachingNoteComponent } from '../../forms/new-chat/teaching-note/teaching-note.component';
import { Store } from '@ngrx/store';
import { AppState, ChatDataActions } from '@iese-chatbot/chat-data-access';
import { Router } from '@angular/router';
import { AcademicContentComponent } from '../../forms/new-chat/academic-content/academic-content.component';
import { SyllabusComponent } from '../../forms/new-chat/syllabus/syllabus.component';
import { AbstractKeywordsComponent } from '../../forms/new-chat/abstract-keywords/abstract-keywords.component';
import { TranslationsComponent } from '../../forms/new-chat/translations/translations.component';

@Component({
  selector: 'icbc-new-chat-form',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    MarkdownParsePipe,
    TeachingNoteComponent,
    AcademicContentComponent,
    SyllabusComponent,
    AbstractKeywordsComponent,
    TranslationsComponent,
  ],
  templateUrl: './new-chat-form.component.html',
})
export class NewChatFormComponent {
  @Input() public newChatSuggestion: NewChatSuggestion | null = null;

  // TODO refactor para abstraer lógica común DIALOG-COMMON
  @Output() public closeForm: EventEmitter<void> = new EventEmitter<void>();
  protected visible = true;

  protected readonly NewChatSuggestionType = NewChatSuggestionType;

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  protected sendData(data: string | null) {
    if (data && this.newChatSuggestion) {
      const chat = this.buildChat(data);
      const payload = {
        chat,
        mock: false,
        shortcut: this.newChatSuggestion.type ?? null,
      };
      this.store.dispatch(ChatDataActions.addChat(payload));
      this.router.navigate(['', 'chat', chat.id]);
    }
    this.visible = false;
    this.closeForm.next();
    this.closeForm.complete();
  }

  // TODO mover esto a un "builder" en `common-utils`?
  private buildChat(message: string): Chat {
    if (this.newChatSuggestion) {
      message = message.trim();
      return {
        id: generateUUID(),
        title: 'Newly generated chat',
        model: 'gpt-3.5-turbo',
        systemMessage: 'You are ChatGPT, a large language model trained by OpenAI.',
        temperature: .7,
        createdAt: new Date(),
        updatedAt: new Date(),
        fromIESEKnowledge: true,
        messages: [
          {
            content: this.newChatSuggestion.initialContext,
            role: 'system' as Role,
            finished: false,
            fromIESEKnowledge: true
          },
          {
            content: message,
            role: 'user' as Role,
            finished: true,
            fromIESEKnowledge: true
          },
        ],
      };
    } else {
      throw new Error('Could not build chat for shortcut. No suggestion has been selected');
    }
  }

}
