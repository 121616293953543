import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { GenericFormComponent } from '../generic-form/generic-form.component';
import { AcademicContentForm } from './academic-content.form';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';
import { NewChatSuggestionType, NewChatSuggestionTypeDescription } from '@iese-chatbot/common-utils';

@Component({
  selector: 'icbc-academic-content',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    DialogModule,
    InputTextModule,
  ],
  templateUrl: './academic-content.component.html',
})
export class AcademicContentComponent extends GenericFormComponent {
  constructor(
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
  ) {
    super();
  }
  protected override form: FormGroup = new AcademicContentForm();

  protected override buildPrompt(): string {
    return `
Write a new ${this.form.value.type}.
The topic is ${this.form.value.topic}.
The learning objective is ${this.form.value.learningObjective}.
The intended audience is ${this.form.value.intendedAudience}.
Be clear and maintain the flow of the narrative.
Avoid structuring it in many sections, only those necessary to follow the structure of this type of content.
    `;
  }
  protected submitSuggestionFormEvent() {
    if (this.form.valid) {
      this.monitoringService.trackEventWithProps('ShortCut_Type_Sent', { ShortCut: NewChatSuggestionTypeDescription(NewChatSuggestionType.ACADEMIC_CONTENT) });
    }
  }
  
  protected handleKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.submitSuggestionFormEvent();
      this.submitForm();
      this.form
    }
  }

}
