import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormComponent } from '../generic-form/generic-form.component';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SyllabusForm } from './syllabus.form';
import { InputTextModule } from 'primeng/inputtext';
import { NewChatSuggestionType, NewChatSuggestionTypeDescription } from '@iese-chatbot/common-utils';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';

@Component({
  selector: 'icbc-syllabus',
  standalone: true,
  imports: [CommonModule, FormsModule, InputTextModule, ReactiveFormsModule],
  templateUrl: './syllabus.component.html',
})
export class SyllabusComponent extends GenericFormComponent {
  constructor(
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
  ) {
    super();
  }
  protected override form: FormGroup = new SyllabusForm();

  protected override buildPrompt(): string {
    return `
Create a syllabus for ${this.form.value.intendedAudience} about ${this.form.value.courseTopic} in ${this.form.value.amountSessions} sessions.
Include for each session academic material that you have indexed.
Focus the syllabus on these learning objectives: ${this.form.value.learningObjectives}
    `;
  }
  protected submitSuggestionFormEvent() {
    if (this.form.valid) {
      this.monitoringService.trackEventWithProps('ShortCut_Type_Sent', { ShortCut: NewChatSuggestionTypeDescription(NewChatSuggestionType.SYLLABUS) });
    }
  }
  
  protected handleKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.submitSuggestionFormEvent();
      this.submitForm();
      this.form
    }
  }

}
