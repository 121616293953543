import { Injectable } from '@angular/core';
import { MonitoringService } from './monitoring.service';
import { AccountInfo } from '@azure/msal-browser';

export const ANON_USER = 'anonymous_user';

@Injectable()
export class MonitoringMockService implements MonitoringService {
  private _user: string = ANON_USER;

  public setContext(user: AccountInfo | null): void {
    this._user = user?.username ?? ANON_USER;
    console.log('[MOCK SET USER]', this._user);
  }

  public trackEvent(name: string): void {
    console.log('[MOCK EVENT]', name, this._user);
  }

  public trackEventWithProps(name: string, properties: any): void {
    console.log('[MOCK EVENT]', name, properties, this._user);
  }

  public trackTrace(message: string): void {
    console.log('[MOCK TRACE]', message, this._user);
  }
}
