import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from '../state/auth.state';
import { AccountInfo } from '@azure/msal-browser';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAccount = createSelector(
  selectAuthState,
  (state: AuthState) => state.account,
);

export const selectUsername = createSelector(
  selectAccount,
  (account: AccountInfo | null) => account?.username ?? 'anonymous',
);

export const selectName = createSelector(
  selectAccount,
  (account: AccountInfo | null) => account?.name ?? 'Anonymous',
);
