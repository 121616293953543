<div *ngIf="chat" class="chat-messages-container">

  <div *ngFor="let message of chat.messages" [class]="'chat-message-row role-' + message.role">

    <div class="chat-message-avatar" *ngIf="message.role === 'assistant' ">
      <svg ieseLogo></svg>
    </div>

    <div class="chat-message-container">
      <div class="chat-message" [innerHTML]="message.content | mdParse"></div>
      <div class="chat-message-citations" *ngIf="message.citations?.length && !generatingMessage">
        <strong>References:</strong>
        <button *ngFor="let citation of message.citations; let i = index" (click)="selectCitation(citation)">
          <span>({{ i + 1 }})</span>
          <span>{{ citation.title ?? citation.filepath }}</span>
        </button>
      </div>
      <div class="chat-message-actions" *ngIf="message.role === 'assistant'">
<!--        <span class="iese-rounded-button pi pi-clone"></span>-->
      </div>
    </div>

  </div>

</div>

<icbc-citation *ngIf="!!selectedCitation" [citation]="selectedCitation"
               (closeCitation)="selectCitation(undefined)">
</icbc-citation>
