import { NewChatSuggestion, NewChatSuggestionType } from '@iese-chatbot/common-utils';

export const professorContext = 'You are a professor at an international and bilingual (English/Spanish) business school. You teach in MBA and Executive Education programs. Your audience is also international. Your tone must be academic and avoid sexist and/or discriminatory vocabulary.';
export const programDirectorContext = 'You are an expert professor or a program director at an international business school, skilled in planning out your course topics, lessons, assignments, and exams. Your tone must be academic and avoid sexist and/or discriminatory vocabulary.';
export const professorOrStaffContext = 'You are a professor or staff member of an international and bilingual (English/Spanish) business school. Your tone must be academic and avoid sexist and/or discriminatory vocabulary.';
export const academicToneOnly = 'Your tone must be academic and avoid sexist and/or discriminatory vocabulary.';

export const newChatSuggestions: NewChatSuggestion[] = [
  {
    type: NewChatSuggestionType.TEACHING_NOTE,
    icon: 'pi pi-file-plus',
    title: 'Create a teaching note',
    initialContext: professorContext,
    helpMessage: 'Develop a comprehensive guide that enables educators at other institutions to effectively teach your case study. This note will include teaching strategies, discussion points, and insights to ensure a rich learning experience.',
    visible: true,
  },
  {
    type: NewChatSuggestionType.ACADEMIC_CONTENT,
    icon: 'pi pi-folder-plus',
    title: 'Create a new academic content',
    initialContext: professorContext,
    helpMessage: 'Create new teaching materials for your academic sessions using both IESE\'s copyrighted knowledge and outside information from the Internet.',
    visible: true,
  },
  {
    type: NewChatSuggestionType.SYLLABUS,
    icon: 'pi pi-plus-circle',
    title: 'Create a syllabus proposal',
    initialContext: programDirectorContext,
    helpMessage: 'Design a detailed proposal for a new program or course. This will include learning objectives, course structure, required materials, and evaluation methods, giving a clear blueprint for academic success.',
    visible: true,
  },
  {
    type: NewChatSuggestionType.ABSTRACT_KEYWORDS,
    icon: 'pi pi-file-edit',
    title: 'Abstract creation and keyword extraction',
    initialContext: professorOrStaffContext,
    helpMessage: 'Summarize your content efficiently and identify key terms. This tool provides concise abstracts and extracts relevant keywords, making your research and documents more accessible and searchable.',
    visible: true,
  },
  {
    type: NewChatSuggestionType.TRANSLATIONS,
    icon: 'pi pi-language',
    title: 'Translate this content',
    initialContext: academicToneOnly,
    helpMessage: 'Translate your academic materials while adhering to our style guide. This ensures that your content maintains its original intent and quality across different languages, facilitating broader dissemination and understanding.',
    visible: false,
  },
];
