<p-dialog *ngIf="newChatSuggestion"
          styleClass="clean-dialog form-dialog"
          [modal]="true"
          [closeOnEscape]="true"
          [dismissableMask]="true"
          [(visible)]="visible"
          (onHide)="closeForm.emit()"
          [style]="{ width: '50rem' }"
          [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">

  <!-- HEADER -->
  <ng-template pTemplate="header">
    <div>
      <strong>{{ newChatSuggestion.title }}</strong>
    </div>
  </ng-template>

  <!-- CONTENT -->
  <ng-template pTemplate="content" class="new-chat-form-content">
    <span class="new-chat-form-content-message">
      <i class="pi pi-asterisk primary"></i> Please, ensure that all fields are completed in <strong>English</strong>
    </span>
    <div [ngSwitch]="newChatSuggestion.type">
      <icbc-teaching-note (sendData)="sendData($event)" *ngSwitchCase="NewChatSuggestionType.TEACHING_NOTE">
      </icbc-teaching-note>

      <icbc-academic-content (sendData)="sendData($event)" *ngSwitchCase="NewChatSuggestionType.ACADEMIC_CONTENT">
      </icbc-academic-content>

      <icbc-syllabus (sendData)="sendData($event)" *ngSwitchCase="NewChatSuggestionType.SYLLABUS">
      </icbc-syllabus>

      <icbc-abstract-keywords (sendData)="sendData($event)" *ngSwitchCase="NewChatSuggestionType.ABSTRACT_KEYWORDS">
      </icbc-abstract-keywords>

      <icbc-translations (sendData)="sendData($event)" *ngSwitchCase="NewChatSuggestionType.TRANSLATIONS">
      </icbc-translations>
    </div>
  </ng-template>

</p-dialog>
