import { createActionGroup, props } from '@ngrx/store';
import { AccountInfo } from '@azure/msal-browser';

export const MonitoringActions = createActionGroup({
  source: '[MONITORING]',
  events: {
    'Set Context': props<{ user: AccountInfo | null }>(),

    'Track Event': props<{ eventName: string }>(),

    'Track Trace': props<{ message: string }>(),
  },
});
