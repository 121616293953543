import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UiState } from '../states/ui.state';

// Create a feature selector for the sidebar state
export const selectUiState = createFeatureSelector<UiState>('ui');

export const selectSidebarVisible = createSelector(
  selectUiState,
  (state: UiState) => state.sidebarVisible,
);
