<p-menu #profileMenu [model]="items" [popup]="true" />

<div class="toolbar-user-profile__container" (click)="profileMenu.toggle($event)">

  <div class="username">{{ name$ | async }}</div>

  <div class="avatar">
    <!-- TODO extract to reusable icon component -->
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#E4E4E4"/>
      <path
        d="M20.417 20.4167C22.1436 20.4167 23.542 19.0182 23.542 17.2917C23.542 15.5651 22.1436 14.1667 20.417 14.1667C18.6904 14.1667 17.292 15.5651 17.292 17.2917C17.292 19.0182 18.6904 20.4167 20.417 20.4167ZM20.417 21.9792C18.3311 21.9792 14.167 23.026 14.167 25.1042V26.6667H26.667V25.1042C26.667 23.026 22.5029 21.9792 20.417 21.9792Z"
        fill="#878787"/>
    </svg>
  </div>

  <div class="pi pi-chevron-down"></div>

</div>
