export const environment = {
  production: false,
  apiUrl: 'https://app-swe-chatbotllmsrv-pre.azurewebsites.net',
  mock: false,
  azure: {
    insights: {
      instrumentationKey: '65ca4b7d-203e-4c1c-a609-afa6df561e74',
    },
    msal: {
      clientId: 'eb954593-c7c1-4469-9059-92d7a72d1eb3',
      authority: 'https://login.microsoftonline.com/ed0cd196-c46d-43d9-813e-500e8c413eda',
      protectedResourceMap: new Map([
        /* PRE */
        ['https://app-swe-chatbotllmsrv-pre.azurewebsites.net/*', [`eb954593-c7c1-4469-9059-92d7a72d1eb3/.default`]],
      ]),
    },
  },
};
