<div id="iese-sidebar" [ngClass]="{'collapsed': (sidebarCollapsed$ | async) === false}">

  <div class="iese-sidebar-group-header">
    <ng-content select="[icbc-sidebar-header]"></ng-content>
  </div>

  <div class="iese-sidebar-group-content">
    <ng-content select="[icbc-sidebar-content]"></ng-content>
  </div>

  <div class="iese-sidebar-group-footer">
    <ng-content select="[icbc-sidebar-footer]"></ng-content>
  </div>

</div>
