import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { StyleClassModule } from 'primeng/styleclass';
import { Store } from '@ngrx/store';
import { AuthActions, selectName } from '@iese-chatbot/auth';
import { Observable } from 'rxjs';
import { BdcWalkService } from 'bdc-walkthrough';

@Component({
  selector: 'icbc-toolbar-user-profile',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, MenuModule, StyleClassModule],
  templateUrl: './toolbar-user-profile.component.html',
})
export class ToolbarUserProfileComponent {
  protected name$: Observable<string>;
  protected items: MenuItem[] = [
    {
      label: 'Open tour',
      command: () => {this.bdcWalkService.reset(); location.reload()},
      target: '_self',
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => this.logout(),
      target: '_self',
    },
  ];

  constructor(
    private store: Store,
    private bdcWalkService: BdcWalkService
  ) {
    this.name$ = store.select(selectName);
  }

  logout(): void {
    this.store.dispatch(AuthActions.logout());
  }
}
