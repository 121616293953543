export enum NewChatSuggestionType {
  TEACHING_NOTE = 'teaching-note',
  ACADEMIC_CONTENT = 'academic-content',
  SYLLABUS = 'syllabus',
  ABSTRACT_KEYWORDS = 'abstract-keywords',
  TRANSLATIONS = 'translations',
}

export function NewChatSuggestionTypeDescription(suggestionType: NewChatSuggestionType | undefined) {
  let suggestionTypeSelected = undefined;
    switch (suggestionType) {
      case NewChatSuggestionType.TEACHING_NOTE:
        suggestionTypeSelected = 'Teaching Note';
        break;
      case NewChatSuggestionType.ACADEMIC_CONTENT:
        suggestionTypeSelected = 'Academic Content';
        break;
      case NewChatSuggestionType.SYLLABUS:
        suggestionTypeSelected = 'Syllabus';
        break;
      case NewChatSuggestionType.ABSTRACT_KEYWORDS:
        suggestionTypeSelected = 'Abstract';
        break;
      case NewChatSuggestionType.TRANSLATIONS:
        suggestionTypeSelected = 'Translations';
        break;
    }
    return suggestionTypeSelected
}

export type NewChatSuggestion = {
  type: NewChatSuggestionType,
  icon: string;
  title: string;
  initialContext: string;
  helpMessage: string;
  visible: boolean;
}
