<p-toolbar id="iese-toolbar">

  <div class="iese-toolbar-group-start">
    <!-- logotipo -->
    <a [routerLink]="['']">
      <svg ieseLogotype class="hidden sm:block"></svg>
      <svg ieseLogo class="block sm:hidden"></svg>
    </a>
    <!-- contenido extra -->
    <ng-content select="[icbc-toolbar-start]"></ng-content>
  </div>

  <div class="iese-toolbar-group-center">
    <ng-content select="[icbc-toolbar-center]"></ng-content>
  </div>

  <div class="iese-toolbar-group-end">
    <ng-content select="[icbc-toolbar-end]"></ng-content>
  </div>

</p-toolbar>
