// noinspection JSUnusedGlobalSymbols

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ConfirmationService } from 'primeng/api';
import { ChatUiActions } from '../actions/chat-ui.actions';
import { Store } from '@ngrx/store';
import { map, mergeMap, of, tap } from 'rxjs';
import { ChatDataActions } from '../actions/chat-data.actions';
import { Router } from '@angular/router';
import { selectActiveChatId } from '../selectors/chat-data.selectors';

@Injectable()
export class ChatUIEffects {

  askChatDeletion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatUiActions.askChatDeletion),
      mergeMap(action => {
        return this.confirmationService.confirm({
          header: 'Please, confirm',
          message: `Are you sure you want to delete the chat "${action.chat.title}"?`,
          key: 'confirmDeleteChat',
          icon: 'pi pi-trash',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          accept: () => {
            this.store.dispatch(ChatUiActions.acceptChatDeletion({ id: action.chat.id }));
          },
          reject: () => {
            this.store.dispatch(ChatUiActions.rejectChatDeletion());
          },
        }).requireConfirmation$.pipe(
          map(() => {
            return { type: 'NO_OP' }; // No operation
          }),
        );
      }),
    );
  });

  acceptChatDeletion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatUiActions.acceptChatDeletion),
      mergeMap(action => {
        const chatId = action.id;
        return of(ChatDataActions.deleteChat({ id: chatId })).pipe(
          tap(() => {
            this.store.select(selectActiveChatId).subscribe(currentChatId => {
              if (currentChatId === chatId) {
                this.router.navigate(['', 'chat']);
              }
            });
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private confirmationService: ConfirmationService,
  ) {}
}
