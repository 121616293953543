import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { selectActiveChatTitle } from '@iese-chatbot/chat-data-access';

@Component({
  selector: 'icbc-toolbar-chat-title',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toolbar-chat-title.component.html',
})
export class ToolbarChatTitleComponent {
  // TODO refactor a variable asignada mediante subscription
  protected title$ = inject(Store).select(selectActiveChatTitle);
}
