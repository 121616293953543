// noinspection JSUnusedGlobalSymbols

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';
import { FileUtils } from '@iese-chatbot/common-utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigatedAction } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  finalize,
  map,
  mergeMap,
  of,
  take,
  takeUntil,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { CHAT_DATA_ACCESS_CONFIG, ChatDataAccessConfig } from '../../config';
import { ChatConstants } from '../../constants/chat.constants';
import { ChatDataService } from '../../services/chat-data.service';
import { ChatDataActions } from '../actions/chat-data.actions';
import { selectActiveChat, selectActiveChatFiles, selectTempFiles } from '../selectors/chat-data.selectors';
import { AppState } from '../states/app.state';

@Injectable()
export class ChatDataEffects {
  // TODO investigar por qué no funcionan los params y queryParams de ActivatedRoute
  selectChatOnRouteChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('@ngrx/router-store/navigated'), // Listen to route change actions
      map((action: RouterNavigatedAction) => {
        let state: ActivatedRouteSnapshot = action.payload.routerState.root;
        while (state.firstChild) {
          state = state.firstChild;
        }

        return state.params['chatId'];
      }),
      mergeMap((chatId) => {
        if (chatId) {
          return of(ChatDataActions.selectChat({ id: chatId }));
        } else {
          return of({ type: 'NO_OP' }); // No operation if chatId is not present
        }
      }),
    );
  });

  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatDataActions.fetchChatHistory),
      exhaustMap(() => {
        // return (!this.config.mock ? this.chatDataService.fetchChatData$() : of(mockChats)).pipe(
        return this.chatDataService.fetchChatData$().pipe(
          map((chatData) => ChatDataActions.fetchSuccess({ chats: chatData })),
          catchError((error) => of(ChatDataActions.fetchError({ error: `${error}` }))),
        );
      }),
    );
  });

  persist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatDataActions.persistChatHistory),
      exhaustMap((action) => {
        return this.chatDataService.saveChatData$(action.chat).pipe(
          map(() => ChatDataActions.persistChatHistorySuccess()),
          catchError((error) => of(ChatDataActions.persistChatHistoryError({ error: `${error}` }))),
        );
      }),
    );
  });

  selectShortcut$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ChatDataActions.selectShortcut),
        tap((action) => {
          if (!action.shortcut) return;
          if (action.mode === 'form') {
            this.monitoringService.trackEventWithProps('ShortCut_Type_Start', {
              ShortCut: action.shortcut.eventName,
            });
          }
          // ¿Posible nuevo evento de Insights 'ShortCut_Type_Help'?
        }),
      );
    },
    { dispatch: false },
  );

  // REFACTOR hacer que este effect lea los archivos de la store para evitar tener que pasarlos en la action y quitar
  //  lógica de los componentes que se debería estar haciendo centralizadamente aquí.
  sendMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatDataActions.addMessage, ChatDataActions.addChat),
      tap(() => {
        this.store.dispatch(ChatDataActions.messageRequestStarted({ timestamp: Date.now() }));
      }),
      map((action) => {
        let shortcut = null;
        if (action.type === ChatDataActions.addChat.type) {
          this.monitoringService.trackEvent('NewChat_Sent');
          if (action.chat.messages.length) {
            this.store.dispatch(
              ChatDataActions.startRenamingChat({
                chat: action.chat,
                auto: true,
              }),
            );
          }
          shortcut = action.shortcut ?? null;

          if (shortcut) {
            this.monitoringService.trackEventWithProps('ShortCut_Type_Sent', {
              ShortCut: shortcut.eventName,
            });
          }
        }
        this.monitoringService.trackEventWithProps('Prompt_Sent', {
          ChatId: action.chat.id,
        });
        if (action.chat.messages.length) {
          return ChatDataActions.startMessageStream({
            chat: action.chat,
            mock: action.mock,
            shortcut,
            files: action.files,
          });
        } else {
          return ChatDataActions.completeMessageStream({
            chat: action.chat,
            manually: true,
          });
        }
      }),
    );
  });

  startMessageStream$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatDataActions.startMessageStream),
      mergeMap((action) => {
        return (
          action.shortcut
            ? this.chatDataService.sendShortcut$(action.chat, action.shortcut, action.files)
            : this.chatDataService.sendMessage$(action.chat, action.mock, action.files)
        ).pipe(
          map((streamSubject) => {
            const payload = {
              chat: action.chat,
              chunk: streamSubject.message ?? '',
              citations: streamSubject.citations ?? [],
            };
            return ChatDataActions.receiveStreamChunk(payload);
          }),
          finalize(() => {
            this.store
              .pipe(
                select(selectActiveChat),
                take(1),
                tap((chat) => {
                  if (chat) {
                    this.store.dispatch(
                      ChatDataActions.completeMessageStream({
                        chat,
                        manually: false,
                      }),
                    );
                  }
                }),
              )
              .subscribe();
          }),
          catchError((error) => {
            this.store.dispatch(
              ChatDataActions.streamError({
                error: error.message ?? 'Error with stream',
              }),
            );
            throw error;
          }),
        );
      }),
    );
  });

  completeMessageStream$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatDataActions.completeMessageStream),
      map((action) => {
        if (action.manually) {
          this.chatDataService.stopMessage();
        }
        try {
          const referencedContent: any[] = [];
          if (action.chat.messages && action.chat.messages[action.chat.messages.length - 1].citations) {
            action.chat.messages[action.chat.messages.length - 1].citations?.forEach((citation) => {
              if (citation && citation.filepath) {
                const citationId = { ContentId: citation.filepath };
                if (!referencedContent.find((c) => c.ContentId === citation.filepath)) {
                  referencedContent.push(citationId);
                }
              }
            });
          }
          this.monitoringService.trackEventWithProps('Prompt_Response', {
            ChatId: action.chat.id,
            ReferencedContent: referencedContent,
          });
        } catch (error) {
          console.error(error);
        }
        return ChatDataActions.persistChatHistory({ chat: action.chat });
      }),
      catchError((error) => of(ChatDataActions.persistChatHistoryError({ error }))),
    );
  });

  fetchChatTitle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatDataActions.startRenamingChat),
      tap((action) => {
        if (!action.auto) {
          this.monitoringService.trackEvent('HistoryChat_Edited');
        }
      }),
      filter((action) => action.auto),
      exhaustMap((action) => {
        return this.chatDataService.fetchTitle$(action.chat, this.config.mock).pipe(
          map((value) =>
            ChatDataActions.confirmRenamingChat({
              chat: action.chat,
              title: value,
            }),
          ),
          catchError((error) => {
            console.error(error);
            return of(ChatDataActions.cancelRenamingChat());
          }),
        );
      }),
    );
  });

  confirmRenameChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatDataActions.confirmRenamingChat),
      map((action) =>
        ChatDataActions.persistChatHistory({
          chat: { ...action.chat, title: action.title },
        }),
      ),
    );
  });

  deleteChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatDataActions.deleteChat),
      mergeMap((action) => {
        return this.chatDataService.deleteChat$(action.id).pipe(
          map(() => {
            this.monitoringService.trackEvent('HistoryChat_Deleted');
            return { type: 'NO_OP' };
          }),
          catchError((error) => of(ChatDataActions.persistChatHistoryError({ error: `${error}` }))),
        );
      }),
    );
  });

  addFileList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatDataActions.addFileList),
      withLatestFrom(this.store.pipe(select(selectTempFiles)), this.store.pipe(select(selectActiveChatFiles))),
      mergeMap(async ([action, tempFiles, chatFiles]) => {
        try {
          let uniqueFiles = await FileUtils.checkDuplicates(action.tempFiles);
          const { validFiles, fileErrors, listErrors } = await FileUtils.validateAndFilterFiles(uniqueFiles);
          const combinedFiles = [...tempFiles, ...validFiles];
          uniqueFiles = await FileUtils.checkDuplicates(combinedFiles);
          uniqueFiles = FileUtils.checkPreviousFilesDuplicates(uniqueFiles, chatFiles);

          return ChatDataActions.addFileListSuccess({
            tempFiles: uniqueFiles,
            tempFileErrors: [...listErrors, ...fileErrors],
          });
        } catch (error) {
          return ChatDataActions.addFileListFailure({
            tempFileErrors: [`Error processing files: ${error instanceof Error ? error.message : 'Unknown error [1]'}`],
          });
        }
      }),
      catchError((error) =>
        of(
          ChatDataActions.addFileListFailure({
            tempFileErrors: [`Error processing files: ${error instanceof Error ? error.message : 'Unknown error [2]'}`],
          }),
        ),
      ),
    );
  });

  waitMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatDataActions.messageRequestStarted),
      mergeMap(() =>
        this.store.pipe(
          select((state) => state.chatData.messageRequestStartedAt),
          distinctUntilChanged(),
          filter((timestamp) => timestamp !== null),
          mergeMap(() =>
            timer(ChatConstants.MAX_WAIT_TIME_MS).pipe(
              takeUntil(this.actions$.pipe(ofType(ChatDataActions.receiveStreamChunk))),
              map(() => ChatDataActions.showWaitMessage()),
            ),
          ),
        ),
      ),
    );
  });

  hideWaitMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ChatDataActions.startMessageStream,
        ChatDataActions.receiveStreamChunk,
        ChatDataActions.completeMessageStream,
      ),
      map(() => ChatDataActions.hideWaitMessage()),
    );
  });

  // TODO desacoplar del servicio de monitoring y despachar acciones de la librería de monitoring en su lugar
  constructor(
    @Inject(CHAT_DATA_ACCESS_CONFIG) private config: ChatDataAccessConfig,
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
    private actions$: Actions,
    private store: Store<AppState>,
    private chatDataService: ChatDataService,
  ) {}
}
