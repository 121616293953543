<div class="new-chat-container">
  <div class="new-chat-container-title">
    <div class="title">Welcome to My AI</div>
    <div class="subtitle">
      the new AI-powered assistant designed to enhance your productivity and
      decision-making process
    </div>
  </div>

  <div class="new-chat-grid-wrapper">
    <div class="new-chat-grid">
      <div *ngFor="let item of visibleShortcuts" class="new-chat-suggestion" [bdcWalkTriggerFor]="popupStep3">
        <div class="new-chat-suggestion-header">
          <!--<i class="{{ item.icon }}"></i>-->
          <span class="new-chat-suggestion-header-label">{{ item.title }}</span>
        </div>
        <div class="filler"></div>
        <div class="new-chat-suggestion-content">
          <button class="access-link" (click)="openShortcutForm(item)">
            <span>Use</span>
            <svg arrowRight></svg>
          </button>
          <div class="filler"></div>
          <button class="iese-rounded-button outlined" (click)="openShortcutHelp(item)">
            <i class="pi pi-question"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<icbc-shortcut-form *ngIf="selectedSuggestion && formIsVisible" [shortcut]="selectedSuggestion"
                    (closeForm)="closeForm()">
</icbc-shortcut-form>

<icbc-new-chat-help *ngIf="selectedSuggestion && helpIsVisible" [newChatSuggestion]="selectedSuggestion"
                    (closeHelp)="closeHelp()">
</icbc-new-chat-help>

<bdc-walk-popup sideNoteText="3/10" [showCloseButton]="false" #popupStep3 name="popupStep3" header="Shortcuts"
                xPosition="before" yPosition="below" [showButton]="true" buttonText="Next"
                [mustCompleted]="{ dialogStep2: true }"
                class="warning">
  <div>
    Quickly access common taks with these shortcuts. Simply click on the
    shortcut buttons to perform specific actions instantly. These shortcuts are
    designed to save you time.
  </div>
  <div class="buttonsSkip">
    <button class="iese-button iese-button-skip" (click)="this.bdcWalkService.setTaskCompleted('dialogStep2', false)">
      Previous
    </button>
    <button class="iese-button iese-button-skip" (click)="endOnboardingTour()">
      Skip
    </button>
  </div>
</bdc-walk-popup>
