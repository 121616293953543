import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { GenericFormComponent } from '../generic-form/generic-form.component';
import { TeachingNoteForm } from './teaching-note.form';
import { NewChatSuggestionType, NewChatSuggestionTypeDescription } from '@iese-chatbot/common-utils';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';

@Component({
  selector: 'icbc-teaching-note',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,

    DialogModule,
    InputTextModule,
  ],
  templateUrl: './teaching-note.component.html',
})
export class TeachingNoteComponent extends GenericFormComponent {
  constructor(
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
  ) {
    super();
  }
  protected override form: FormGroup = new TeachingNoteForm();

  protected override buildPrompt(): string {
    return `
Write a teaching note of the IESE case ${this.form.value.caseTitle} based on the IESE teaching note’s template.
The learning objective is ${this.form.value.learningObjective}.
The duration of the session is ${this.form.value.duration} minutes.
The intended audience for the case is ${this.form.value.intendedAudience}.
The tone should be academic.
Also suggest preparation questions about the case.
    `;
  }
  protected submitSuggestionFormEvent() {
    if (this.form.valid) {
      this.monitoringService.trackEventWithProps('ShortCut_Type_Sent', { ShortCut: NewChatSuggestionTypeDescription(NewChatSuggestionType.TEACHING_NOTE) });
    }
  }
  
  protected handleKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.submitSuggestionFormEvent();
      this.submitForm();
      this.form
    }
  }

}
