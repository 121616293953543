import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CHAT_DATA_ACCESS_CONFIG, ChatDataAccessConfig } from '../config';
import { map, Observable } from 'rxjs';
import { Chat, ChatEntity, NewChatSuggestionType, Stream, StreamSubject } from '@iese-chatbot/common-utils';
import { XhrService } from './xhr.service';

@Injectable({
  providedIn: 'root',
})
export class ChatDataService {
  private readonly apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private xhrService: XhrService,
    @Inject(CHAT_DATA_ACCESS_CONFIG) config: ChatDataAccessConfig,
  ) {
    this.apiUrl = config.apiUrl;
  }

  public fetchChatData$(): Observable<Chat[]> {
    return this.httpClient.get<ChatEntity[]>(
      `${this.apiUrl}/entities/user`,
    ).pipe(
      map(result => result.map(entity => entity.chat)),
    );
  }

  public fetchTitle$(chat: Chat, mock: boolean): Observable<string> {
    let path = `${this.apiUrl}/event/title`;
    if (mock) path += '/mock';

    return this.httpClient.post<Stream>(
      path,
      chat,
    ).pipe(
      map(result => result.choices[0].message?.content.replace(/"/g, '') ?? ''),
    );
  }

  public saveChatData$(chat: Chat): Observable<ChatEntity> {
    const chatEntity: ChatEntity = { id: chat.id, chat };

    return this.httpClient.post<ChatEntity>(
      `${this.apiUrl}/entities/save`,
      chatEntity,
    );
  }

  public deleteChat$(id: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${this.apiUrl}/entities/${id}`,
    );
  }

  public sendMessage$(chat: Chat, mock: boolean): Observable<StreamSubject> {
    let path = `${this.apiUrl}/event/stream`;
    if (mock) path += '/mock';

    const body = {
      messages: chat.messages,
      fromIESEKnowledge: chat.fromIESEKnowledge
    };

    return this.xhrService.xhrConnection(
      path,
      body as never,
    );
  }

  public sendShortcut$(chat: Chat, shortcut: NewChatSuggestionType): Observable<StreamSubject> {
    const path = `${this.apiUrl}/event/shortcut/${shortcut}`;
    const body = {
      messages: chat.messages,
      fromIESEKnowledge: chat.fromIESEKnowledge
    }

    return this.xhrService.xhrConnection(
      path,
      body as never,
    )
  }

  public stopMessage(): void {
    this.xhrService.stopStream();
  }

}
