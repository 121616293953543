import { Route } from '@angular/router';
import { ChatLayoutComponent } from './pages/chat-layout/chat-layout.component';
import { inject } from '@angular/core';
import { ChatMessagesComponent } from './pages/chat-messages/chat-messages.component';
import { NewChatComponent } from './pages/new-chat/new-chat.component';
import { ChatDataActions, chatIdGuard, selectAllChats } from '@iese-chatbot/chat-data-access';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { MsalGuard } from '@azure/msal-angular';

export const chatFeatureRoutes: Route[] = [
  {
    path: 'chat',
    component: ChatLayoutComponent,
    canActivate: [MsalGuard],
    resolve: {
      fetch: async () => {
        const store = inject(Store);
        const chats = await firstValueFrom(store.select(selectAllChats));
        if (!(chats.length > 0)) {
          store.dispatch(ChatDataActions.fetchChatHistory());
        }
      },
    },
    children: [
      {
        path: ':chatId',
        component: ChatMessagesComponent,
        canActivate: [chatIdGuard],
      },
      {
        path: '',
        component: NewChatComponent,
        resolve: {
          unselect: () => {
            inject(Store).dispatch(ChatDataActions.selectChat({ id: null }));
          },
        },
      },
    ],
  },
];
