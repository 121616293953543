<div class="sidebar-support-center">
  <h1>Support center</h1>
  <div class="iese-sidebar-footer-buttons">
    <a class="iese-rounded-button primary" target="_blank" [href]="toolsAndTips"
       [pTooltip]="'General tips when using Generative AI'" tooltipPosition="top">
      <i class="pi pi-info-circle"></i>
    </a>
    <a class="iese-rounded-button primary" target="_blank" (click)="sendSupportEventAndMailTo()" href="javascript:void(0)"
       [pTooltip]="'If you have any questions or problems, please contact us at myai.support@iese.edu'"
       tooltipPosition="top">
      <i class="pi pi-at"></i>
    </a>
    <a class="iese-rounded-button primary" target="_blank" [href]="releaseNotes"
       [pTooltip]="'Please note that this platform is in continuous development. You can check the progress here'"
       tooltipPosition="top">
      <i class="pi pi-book"></i>
    </a>
  </div>
</div>
