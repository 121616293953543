import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AccountInfo } from '@azure/msal-browser';

export const AuthActions = createActionGroup({
  source: '[AUTH]',
  events: {
    'Store Auth Data': props<{ account: AccountInfo | null }>(),
    'Logout': emptyProps(),
  },
});
