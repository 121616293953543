import { FormControl, FormGroup, Validators } from '@angular/forms';

export const DEFAULT_SYLLABUS_INTENDED_AUDIENCE = '';
export const DEFAULT_SYLLABUS_COURSE_TOPIC = '';
export const DEFAULT_SYLLABUS_AMOUNT_SESSIONS = 3;
export const DEFAULT_SYLLABUS_LEARNING_OBJECTIVES = '';

export type SyllabusOptions = {
  intendedAudience: string;
  courseTopic: string;
  amountSessions: number;
  learningObjectives: string;
}

export class SyllabusForm extends FormGroup {

  constructor(initialValue?: Partial<SyllabusOptions>) {
    super({
      intendedAudience: new FormControl<string>(
        initialValue?.intendedAudience ?? DEFAULT_SYLLABUS_INTENDED_AUDIENCE, [Validators.required],
      ),
      courseTopic: new FormControl<string>(
        initialValue?.courseTopic ?? DEFAULT_SYLLABUS_COURSE_TOPIC, [Validators.required],
      ),
      amountSessions: new FormControl<number>(
        initialValue?.amountSessions ?? DEFAULT_SYLLABUS_AMOUNT_SESSIONS, [Validators.min(1), Validators.max(999)],
      ),
      learningObjectives: new FormControl<string>(
        initialValue?.learningObjectives ?? DEFAULT_SYLLABUS_LEARNING_OBJECTIVES, [Validators.required],
      ),
    });
  }

}
