import { Provider } from '@angular/core';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AuthService } from '../services/auth.service';
import { AUTH_CONFIG, AuthConfig } from '../config';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

export const provideAuth = (authConfig: AuthConfig): Provider[] => {
  const msalInstance = new PublicClientApplication(authConfig.msal);
  return [
    { provide: AUTH_CONFIG, useValue: authConfig },
    AuthService,
    {
      provide: MSAL_INSTANCE,
      useValue: msalInstance,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: [`${authConfig.msal.auth.clientId}/.default`],
        },
        loginFailedRoute: '/login-failed',
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: authConfig.protectedResourceMap,
      },
    },
    MsalGuard,
    MsalInterceptor,
    MsalService,
    MsalBroadcastService,
  ];
};
