import { environment } from '../environment/environment';
import { AuthConfig } from '@iese-chatbot/auth';

export const authConfig: AuthConfig = {
  msal: {
    auth: {
      clientId: environment.azure.msal.clientId,
      authority: environment.azure.msal.authority,
      redirectUri: '/',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  },
  protectedResourceMap: environment.azure.msal.protectedResourceMap,
};
