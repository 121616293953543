import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import {
  Citation,
  CITATION_DOCUMENT_CATEGORY,
  CITATION_DOWNLOAD_LINK,
  CITATION_UPLOAD_DATE,
  CitationMetadata,
  CitationMetadataPipe,
  MarkdownParsePipe,
} from '@iese-chatbot/common-utils';

@Component({
  selector: 'icbc-citation',
  standalone: true,
  imports: [CommonModule, DialogModule, AccordionModule, MarkdownParsePipe],
  providers: [CitationMetadataPipe],
  templateUrl: './citation.component.html',
  styleUrls: ['./citation.component.scss']
})
export class CitationComponent implements OnInit {
  @Input() public citation: Citation | undefined;
  // TODO refactor para abstraer lógica común DIALOG-COMMON
  @Output() public closeCitation: EventEmitter<void> = new EventEmitter();
  protected visible = true;
  protected documentCategory: string | undefined;
  protected uploadDate: string | undefined;
  protected detailLink: string | undefined;
  protected downloadLink: string | undefined;

  constructor(
    private citationMetadataPipe: CitationMetadataPipe,
  ) {}

  ngOnInit(): void {
    let metadata: CitationMetadata = {};
    if (this.citation) {
      metadata = this.citationMetadataPipe.transform(this.citation.content);
      this.documentCategory = metadata[CITATION_DOCUMENT_CATEGORY];
      this.uploadDate = metadata[CITATION_UPLOAD_DATE]?.replace('-', '/');
      this.detailLink = metadata[CITATION_DOWNLOAD_LINK];
      this.downloadLink = this.detailLink?.replace('/Detail', '/Download');
    }
  }
}
