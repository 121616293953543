import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'svg[ieseLogotype]',
  standalone: true,
  styles: [ ':host { width: 97px; height: 32px; }' ],
  template: `
    <svg width="97" height="32" viewBox="0 0 97 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M67.3259 17.328L68.0696 17.7302C68.0816 17.738 68.6745 18.0473 68.6745 18.0473C71.4754 19.5066 73.1927 20.5287 73.1927 23.046C73.1927 27.1139 69.5071 27.5528 67.9217 27.5528C65.3587 27.5528 62.9207 26.5022 61.992 26.0503L60.3282 25.2401V30.9769L61.21 31.1978C61.9818 31.3925 64.6647 31.8958 67.7569 31.8958C72.8545 31.8958 78.3026 29.4943 78.3026 22.7534C78.3026 18.1431 75.9352 16.3023 73.0751 14.5303L73.0636 14.5234L69.5465 12.5803C66.1588 10.8182 64.9865 10.0883 64.9865 7.73118C64.9865 5.62345 66.67 4.25946 69.2745 4.25946C71.4594 4.25946 73.4922 5.06799 75.0209 5.77301L76.6625 6.5302V0.906338L75.7643 0.694832C73.7877 0.227807 71.8646 0 69.8876 0C63.9826 0 60.165 3.24717 60.165 8.27115C60.165 13.4744 63.8063 15.4346 67.3259 17.328Z"
        fill="red" />
      <path d="M38.041 31.7576H33.1638V0.773615H38.041V31.7576Z" fill="red" />
      <path
        d="M80.9603 31.7576V0.773615H96.1895V5.03286H85.946V13.4707H95.687V17.7323H85.946V27.414H96.5673V31.7576H80.9603Z"
        fill="red" />
      <path d="M1.74028 0.253239H2.23134V14.2933H0V1.98319C0 1.02672 0.780762 0.253239 1.74028 0.253239Z"
            fill="red" />
      <path d="M3.62781 14.2933H5.92285V0.253239H3.62781V14.2933Z" fill="red" />
      <path d="M7.316 14.2933H9.52761V0.253239H7.316V14.2933Z" fill="red" />
      <path d="M10.9168 14.2933H13.1884V0.253239H10.9168V14.2933Z" fill="red" />
      <path
        d="M47.6085 27.0964H57.9717V31.4394H42.7468V0.457211H57.606V4.71627H47.6085V13.1537H57.1129V17.4148H47.6085V27.0964Z"
        fill="red" />
      <path d="M18.5304 31.2397C19.3313 30.9598 20.0973 30.6134 20.8258 30.1993V15.4114H18.5304V31.2397Z"
            fill="red" />
      <path d="M22.2193 29.3013C23.0288 28.703 23.7656 28.0241 24.4272 27.2698V15.4116H22.2193V29.3013Z" fill="red" />
      <path d="M14.5858 32C15.4586 31.9568 16.3113 31.8337 17.1381 31.6414V15.4112H14.5858V32Z" fill="red" />
      <path d="M25.8201 25.3924C27.1092 23.2969 27.855 20.8342 27.855 18.2028V15.4112H25.8201V25.3924Z" fill="red" />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0 15.4114V18.203C0 25.5841 5.84174 31.6155 13.1891 31.9957V15.4114H0ZM6.95209 21.6628C5.72054 21.6628 4.72075 20.6737 4.72075 19.4556C4.72075 18.2363 5.72054 17.2456 6.95209 17.2456C8.18323 17.2456 9.18055 18.2363 9.18055 19.4556C9.18055 20.6737 8.18323 21.6628 6.95209 21.6628ZM6.95209 27.5649C5.72054 27.5649 4.72075 26.575 4.72075 25.3536C4.72075 24.1335 5.72054 23.1448 6.95209 23.1448C8.18323 23.1448 9.18055 24.1335 9.18055 25.3536C9.18055 26.575 8.18323 27.5649 6.95209 27.5649Z"
            fill="red" />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M26.116 0.253239H14.5858V14.2933H27.8559V1.98319C27.8559 1.02713 27.076 0.253239 26.116 0.253239ZM20.8723 6.46473C19.6408 6.46473 18.6439 5.47566 18.6439 4.25594C18.6439 3.03377 19.6408 2.0447 20.8723 2.0447C22.1039 2.0447 23.0979 3.03377 23.0979 4.25594C23.0979 5.47566 22.1039 6.46473 20.8723 6.46473ZM20.8723 12.2822C19.6408 12.2822 18.6439 11.2935 18.6439 10.0738C18.6439 8.85285 19.6408 7.86378 20.8723 7.86378C22.1039 7.86378 23.0979 8.85285 23.0979 10.0738C23.0979 11.2935 22.1039 12.2822 20.8723 12.2822Z"
            fill="red" />
    </svg>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IeseLogotypeUiComponent {
  @HostBinding('attr.fill')
  public readonly fill = 'currentColor';

  @HostBinding('attr.viewBox')
  public readonly viewBox = '0 0 97 32';
}
