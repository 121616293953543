import { Pipe, PipeTransform } from '@angular/core';
import { CitationMetadata } from '../model/citation-metadata';

export const CITATION_METADATA_REGEXP = /\["([^"]+)"\s*:\s*"([^"]+)"]/g;
//export const CITATION_METADATA_REMOVE_REGEXP = /\["[^"]*"\s*:\s*"[^"]*"\]|\["[^"]*"\s*:\s*\([^)]*\)\]/g;

@Pipe({
  standalone: true,
  name: 'citationMetadata',
})
export class CitationMetadataPipe implements PipeTransform {

  transform(content: string): CitationMetadata {
    // Find all matches
    const matches = [...content.matchAll(CITATION_METADATA_REGEXP)];
    const metadata: CitationMetadata = {};

    matches.forEach(match => {
      metadata[match[1]] = match[2].replace('{', '').replace('}', '').trim();
    });
    
    return metadata;
  }

}
