// noinspection JSUnusedGlobalSymbols

import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MONITORING_SERVICE } from '../../config';
import { MonitoringService } from '../../services/monitoring.service';
import { MonitoringActions } from '../actions/monitoring.actions';
import { tap } from 'rxjs';

@Injectable()
export class MonitoringEffects {

  trackEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MonitoringActions.trackEvent),
      tap(action => {
        this.monitoringService.trackEvent(action.eventName);
      }),
    );
  }, { dispatch: false });

  trackTrace$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MonitoringActions.trackTrace),
      tap(action => {
        this.monitoringService.trackTrace(action.message);
      }),
    );
  }, { dispatch: false });

  setContext$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MonitoringActions.setContext),
      tap(action => {
        this.monitoringService.setContext(action.user);
      }),
    );
  }, { dispatch: false });

  constructor(
    private actions$: Actions,
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
  ) {}

}
