import { createFeature, createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatDataState } from '../states/chat-data.state';
import { Chat } from '@iese-chatbot/common-utils';
import { chatDataAdapter, chatDataReducer } from '../reducers/chat-data.reducer';
import { Dictionary } from '@ngrx/entity';
import { GroupedChats } from '@iese-chatbot/common-utils';
import { getFormattedDate } from '@iese-chatbot/common-utils';

export const selectChatDataState = createFeatureSelector<ChatDataState>('chatData');

export const chatDataFeature = createFeature({
  name: 'chatData',
  reducer: chatDataReducer,
  /*extraSelectors: ({ selectChatDataState, selectEntities, selectActive, selectLoading, selectError }) => ({
    ...chatDataAdapter.getSelectors(selectChatDataState),
  }),*/
});

// Selectors provided by the adapter
const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = chatDataAdapter.getSelectors(chatDataFeature.selectChatDataState);

export const selectAllChats = selectAll;
export const selectChatEntities = selectEntities;
export const selectChatIds = selectIds;
export const selectChatTotal = selectTotal;

export const selectChatDataLoading = createSelector(
  selectChatDataState,
  (state: ChatDataState) => state.loading,
);

export const selectChatDataErrors = createSelector(
  selectChatDataState,
  (state: ChatDataState) => state.error,
);

export const selectActiveChatId = createSelector(
  chatDataFeature.selectChatDataState,
  (state: ChatDataState) => state.active,
);

export const selectRenaming = createSelector(
  chatDataFeature.selectChatDataState,
  (state: ChatDataState) => state.renaming,
);

export const selectRenamingAuto = createSelector(
  chatDataFeature.selectChatDataState,
  (state: ChatDataState) => state.renamingAuto,
);

export const selectActiveChat = createSelector(
  selectActiveChatId,
  selectEntities,
  (activeId: string | null, entities: Dictionary<Chat>) => activeId ? entities[activeId] : undefined,
);

export const selectActiveChatTitle = createSelector(
  selectActiveChat,
  (activeChat: Chat | undefined) => activeChat ? activeChat.title : undefined,
);

export const selectChatMessages = createSelector(
  selectActiveChat.apply,
  (state: Chat | null) => state?.messages ?? [],
);

export const selectGeneratingMessage = createSelector(
  selectChatDataState,
  (state: ChatDataState) => state.generatingMessage,
);

export const selectGroupedChats = createSelector(
  selectAllChats,
  (chats: Chat[]): GroupedChats => {
    return chats.reduce((groups: GroupedChats, chat: Chat) => {
      const dateCategory = getFormattedDate(chat.updatedAt);
      if (!groups[dateCategory]) {
        groups[dateCategory] = [];
      }
      groups[dateCategory].push(chat);
      return groups;
    }, {});
  }
);
