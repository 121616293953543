import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';

@Component({
  selector: 'icbc-sidebar-release-notes',
  standalone: true,
  imports: [CommonModule, TooltipModule],
  templateUrl: './sidebar-release-notes.component.html',
})
export class SidebarReleaseNotesComponent {
  protected readonly toolsAndTips = 'https://ieseinternal.zendesk.com/hc/en-us/articles/26284001832465-General-tips-when-using-Generative-AI';
  protected readonly releaseNotes = 'https://ieseinternal.zendesk.com/hc/en-us/articles/26252745113105-Release-Notes';
  
  constructor(
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
  ){
  }
  protected sendSupportEventAndMailTo() {
    this.monitoringService.trackEvent('Support_Click');
    window.location.href = 'mailto:myai.support@iese.edu';
  }
}
