import { AfterViewChecked, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { AppState, selectActiveChat, selectGeneratingMessage } from '@iese-chatbot/chat-data-access';
import { Store } from '@ngrx/store';
import { Chat, Citation, MarkdownParsePipe } from '@iese-chatbot/common-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IeseLogoUiComponent } from '@iese-chatbot/ui-design-system';
import { CitationComponent } from '../../dialogs/citation/citation.component';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';

@Component({
  selector: 'icbc-main-chat-window',
  standalone: true,
  imports: [CommonModule, ButtonModule, MarkdownParsePipe, IeseLogoUiComponent, CitationComponent],
  templateUrl: './chat-messages.component.html',
})
export class ChatMessagesComponent implements AfterViewChecked {
  protected chat?: Chat;
  protected generatingMessage = false;
  // TODO refactor para usar ngrx?
  protected selectedCitation: Citation | undefined;
  protected lastMessageContent = '';

  constructor(
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
    private store: Store<AppState>) {
    this.store.select(selectActiveChat).pipe(
      takeUntilDestroyed(),
    ).subscribe({
      next: (chat) => {
        this.chat = chat;
      },
    });
    this.store.select(selectGeneratingMessage).pipe(
      takeUntilDestroyed(),
    ).subscribe({
      next: generatingMessage => { this.generatingMessage = generatingMessage; },
    });
  }

  ngAfterViewChecked(): void {
    if (this.lastMessageContent != this.chat?.messages.at(-1)?.content) {
      this.scrollToBottom();
      this.lastMessageContent = this.chat?.messages.at(-1)?.content ?? '';
    }
  }

  protected scrollToBottom(): void {
    const outletElement = document.querySelector('.main-chat-outlet') as HTMLDivElement;
    try {
      outletElement.scrollTop = outletElement?.scrollHeight;
    } catch (error) {
      console.error(error);
    }
  }

  protected selectCitation(citation: Citation | undefined) {
    this.selectedCitation = citation;
    try {
      if (citation && citation.filepath) {
        this.monitoringService.trackEventWithProps('Citation_Click', { ChatId: this.chat?.id, ContentId: citation.filepath });
      }
    } catch (error) {
      console.error(error);
    }
  }
}
