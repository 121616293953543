import { uiReducer } from '@iese-chatbot/ui-state';
import { chatDataReducer } from '@iese-chatbot/chat-data-access';
import { routerReducer } from '@ngrx/router-store';
import { authReducer } from '@iese-chatbot/auth';

export const appStore = {
  auth: authReducer,
  ui: uiReducer,
  chatData: chatDataReducer,
  router: routerReducer,
};
