<p-dialog *ngIf="newChatSuggestion"
          styleClass="help-dialog"
          [modal]="true"
          [closeOnEscape]="true"
          [dismissableMask]="true"
          [(visible)]="visible"
          (onHide)="closeHelp.emit()"
          [style]="{ width: '50rem' }"
          [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">

  <!-- HEADER -->
  <ng-template pTemplate="header">
    <div>
      <strong>{{ newChatSuggestion.title }}</strong>
    </div>
  </ng-template>

  <!-- CONTENT -->
  <ng-template pTemplate="content">
    <div class="dialog-content" [innerHTML]="newChatSuggestion.helpMessage"></div>
  </ng-template>

</p-dialog>
