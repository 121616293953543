import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'svg[arrowRight]',
  standalone: true,
  styles: [ ':host { width: 24px; height: 24px; }' ],
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6655 5.29289C14.275 4.90237 13.6418 4.90237 13.2513 5.29289C12.8607 5.68342 12.8607 6.31658 13.2513 6.70711L17.5442 11H3.95833C3.42906 11 3 11.4477 3 12C3 12.5523 3.42906 13 3.95833 13H17.5442L13.2513 17.2929C12.8607 17.6834 12.8607 18.3166 13.2513 18.7071C13.6418 19.0976 14.275 19.0976 14.6655 18.7071L20.6655 12.7071C21.056 12.3166 21.056 11.6834 20.6655 11.2929L14.6655 5.29289Z"
        fill="#FF0000"/>
    </svg>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrowRightUiComponent {
  @HostBinding('attr.fill')
  public readonly fill = 'currentColor';

  @HostBinding('attr.viewBox')
  public readonly viewBox = '0 0 24 24';
}
