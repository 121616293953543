<form class="p-fluid" [formGroup]="form" (ngSubmit)="submitSuggestionFormEvent(); submitForm()">

  <div class="form-field form-row">
    <label class="form-label" for="content">Content</label>
    <textarea class="p-inputtext form-input" id="content" type="text" pInputText
              formControlName="content" rows="5">
    </textarea>
  </div>

  <div class="form-field form-row">
    <label class="form-label" for="language">Language</label>
    <input class="p-inputtext form-input" id="language" type="text" pInputText
           formControlName="language" (keydown)="handleKeyup($event)" />
  </div>

  <div class="form-actions">
    <button class="iese-button" (click)="close()">
      <span>Cancel</span>
    </button>
    <button class="iese-button primary" type="reset">
      <i class="pi pi-delete-left"></i>
      <span>Clear</span>
    </button>
    <button class="iese-button primary" type="submit" [ngClass]="{ 'disabled': !form.valid}">
      <i class="pi pi-send"></i>
      <span>Send</span>
    </button>
  </div>

</form>
