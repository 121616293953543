import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormComponent } from '../generic-form/generic-form.component';
import { TranslationsForm } from './translations.form';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';
import { NewChatSuggestionType, NewChatSuggestionTypeDescription } from '@iese-chatbot/common-utils';

@Component({
  selector: 'icbc-translations',
  standalone: true,
  imports: [CommonModule, InputTextModule, PaginatorModule, ReactiveFormsModule],
  templateUrl: './translations.component.html',
})
export class TranslationsComponent extends GenericFormComponent {
  constructor(
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
  ) {
    super();
  }
  protected override form: FormGroup = new TranslationsForm();

  protected override buildPrompt(): string {
    return `
Translate this content ${this.form.value.content} to ${this.form.value.language} language.
    `;
  }
  protected submitSuggestionFormEvent() {
    if (this.form.valid) {
      this.monitoringService.trackEventWithProps('ShortCut_Type_Sent', { ShortCut: NewChatSuggestionTypeDescription(NewChatSuggestionType.TRANSLATIONS) });
    }
  }
  
  protected handleKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.submitSuggestionFormEvent();
      this.submitForm();
      this.form
    }
  }
}
