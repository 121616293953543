import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Chat } from '@iese-chatbot/common-utils';

export const ChatUiActions = createActionGroup({
  source: '[CHAT UI]',
  events: {
    'Ask Chat Deletion': props<{ chat: Chat }>(),
    'Accept Chat Deletion': props<{ id: string }>(),
    'Reject Chat Deletion': emptyProps(),
  },
});
