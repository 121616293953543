import { FormControl, FormGroup, Validators } from '@angular/forms';

export const DEFAULT_ABSTRACT_KEYWORDS_SUBJECT = '';

export type AbstractKeywordsOptions = {
  subject: string;
}

export class AbstractKeywordsForm extends FormGroup {

  constructor(initialValue?: Partial<AbstractKeywordsOptions>) {
    super({
      subject: new FormControl<string>(
        initialValue?.subject ?? DEFAULT_ABSTRACT_KEYWORDS_SUBJECT, [Validators.required],
      ),
    });
  }

}
