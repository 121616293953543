import { FormControl, FormGroup, Validators } from '@angular/forms';

export const DEFAULT_ACADEMIC_CONTENT_TYPE = '';
export const DEFAULT_ACADEMIC_CONTENT_TOPIC = '';
export const DEFAULT_ACADEMIC_CONTENT_LEARNING_OBJECTIVE = '';
export const DEFAULT_ACADEMIC_CONTENT_INTENDED_AUDIENCE = '';

export type AcademicContentOptions = {
  type: string;
  topic: string;
  learningObjective: string;
  intendedAudience: string;
}

export class AcademicContentForm extends FormGroup {

  constructor(initialValue?: Partial<AcademicContentOptions>) {
    super({
      type: new FormControl<string>(
        initialValue?.type ?? DEFAULT_ACADEMIC_CONTENT_TYPE, [Validators.required],
      ),
      topic: new FormControl<string>(
        initialValue?.topic ?? DEFAULT_ACADEMIC_CONTENT_TOPIC, [Validators.required],
      ),
      learningObjective: new FormControl<string>(
        initialValue?.learningObjective ?? DEFAULT_ACADEMIC_CONTENT_LEARNING_OBJECTIVE, [Validators.required],
      ),
      intendedAudience: new FormControl<string>(
        initialValue?.intendedAudience ?? DEFAULT_ACADEMIC_CONTENT_INTENDED_AUDIENCE, [Validators.required],
      ),
    });
  }

}
