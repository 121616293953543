import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ChatDataActions, ChatUiActions, selectRenaming, selectRenamingAuto } from '@iese-chatbot/chat-data-access';
import { Store } from '@ngrx/store';
import { UiActions } from '@iese-chatbot/ui-state';
import { TooltipModule } from 'primeng/tooltip';
import { Observable } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { Chat } from '@iese-chatbot/common-utils';

@Component({
  selector: 'icbc-sidebar-chat-history-item',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, TooltipModule, FormsModule],
  templateUrl: './sidebar-chat-history-item.component.html',
})
export class SidebarChatHistoryItemComponent implements OnInit {
  @Input() public chatItem!: Chat;

  @ViewChild('newTitleInput') newTitleInput!: ElementRef<HTMLInputElement>;
  protected renaming$: Observable<string | null>;
  protected renamingAuto$: Observable<string | null>;
  protected newTitle = '';

  constructor(private store: Store) {
    this.renaming$ = this.store.select(selectRenaming);
    this.renamingAuto$ = this.store.select(selectRenamingAuto);
  }

  ngOnInit() {
    this.newTitle = this.chatItem?.title ?? '';
  }

  protected selectChat(chatItem: Chat) {
    if (chatItem) {
      this.store.dispatch(ChatDataActions.selectChat({ id: chatItem.id }));
      this.store.dispatch(UiActions.sidebarHideMobile());
    }
  }

  protected confirmDelete(event: MouseEvent) {
    this.bypassEvents(event);
    this.store.dispatch(ChatUiActions.askChatDeletion({ chat: this.chatItem }));
  }

  protected startRenaming(event: MouseEvent) {
    this.bypassEvents(event);
    this.store.dispatch(ChatDataActions.startRenamingChat({ chat: this.chatItem, auto: false }));
    setTimeout(() => {
      this.newTitleInput.nativeElement.focus();
    }, 0);
  }

  protected confirmRenaming(event: MouseEvent) {
    this.bypassEvents(event);
    this.store.dispatch(ChatDataActions.confirmRenamingChat({ chat: this.chatItem, title: this.newTitle }));
  }

  protected cancelRenaming(event: MouseEvent) {
    this.bypassEvents(event);
    this.store.dispatch(ChatDataActions.cancelRenamingChat());
  }

  protected bypassEvents(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
