import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { newChatSuggestions } from '../../data/new-chat-suggestions.data';
import { NewChatSuggestion, NewChatSuggestionType, NewChatSuggestionTypeDescription } from '@iese-chatbot/common-utils';
import { ArrowRightUiComponent } from '@iese-chatbot/ui-design-system';
import { NewChatHelpComponent } from '../../dialogs/new-chat-help/new-chat-help.component';
import { BdcWalkModule, BdcWalkService } from 'bdc-walkthrough';
import { NewChatFormComponent } from '../../dialogs/new-chat-form/new-chat-form.component';
import { Store } from '@ngrx/store';
import { AppState } from '@iese-chatbot/chat-data-access';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';

@Component({
  selector: 'icbc-new-chat',
  standalone: true,
  imports: [CommonModule, ArrowRightUiComponent, NewChatHelpComponent, BdcWalkModule, NewChatFormComponent],
  templateUrl: './new-chat.component.html',
})
export class NewChatComponent {

  protected readonly newChatSuggestions = newChatSuggestions.filter(i => i.visible);
  protected selectedSuggestion: NewChatSuggestion | null = null;
  protected formIsVisible = false;
  protected helpIsVisible = false;

  constructor(
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
    private store: Store<AppState>,
    protected bdcWalkService: BdcWalkService
  ) {}

  protected closeForm() {
    this.selectedSuggestion = null;
    this.formIsVisible = false;
  }

  protected closeHelp() {
    this.selectedSuggestion = null;
    this.helpIsVisible = false;
  }

  protected selectSuggestionEvent() {
    const suggestionTypeSelected = NewChatSuggestionTypeDescription(this.selectedSuggestion?.type);
    // switch (this.selectedSuggestion?.type) {
    //   case NewChatSuggestionType.TEACHING_NOTE:
    //     suggestionTypeSelected = 'Teaching Note';
    //     break;
    //   case NewChatSuggestionType.ACADEMIC_CONTENT:
    //     suggestionTypeSelected = 'Academic Content';
    //     break;
    //   case NewChatSuggestionType.SYLLABUS:
    //     suggestionTypeSelected = 'Syllabus';
    //     break;
    //   case NewChatSuggestionType.ABSTRACT_KEYWORDS:
    //     suggestionTypeSelected = 'Abstract';
    //     break;
    //   case NewChatSuggestionType.TRANSLATIONS:
    //     suggestionTypeSelected = 'Translations';
    //     break;
    // }
    if (suggestionTypeSelected) {
      this.monitoringService.trackEventWithProps('ShortCut_Type_Start', { ShortCut: suggestionTypeSelected });
    }
  }

  // TODO refactor código duplicado
  endOnboardingTour() {
    this.bdcWalkService.setTaskCompleted('dialogStep1', true);
    this.bdcWalkService.setTaskCompleted('dialogStep2', true);
    this.bdcWalkService.setTaskCompleted('popupStep3', true);
    this.bdcWalkService.setTaskCompleted('popupStep4', true);
    this.bdcWalkService.setTaskCompleted('popupStep5', true);
    // this.bdcWalkService.setTaskCompleted('popupStep6', true);
    // this.bdcWalkService.setTaskCompleted('popupStep7', true);
    this.bdcWalkService.setTaskCompleted('dialogStep8', true);
    this.bdcWalkService.setTaskCompleted('popupStep9', true);
    // this.bdcWalkService.setTaskCompleted('popupStep10', true);
    this.bdcWalkService.setTaskCompleted('popupStep11', true);
    this.bdcWalkService.setTaskCompleted('dialogStep12', true);
    this.bdcWalkService.setTaskCompleted('dialogStep13', true);
  }
}
