import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarChatHistoryItemComponent } from '../sidebar-chat-history-item/sidebar-chat-history-item.component';
import { ChatDataActions, selectGroupedChats } from '@iese-chatbot/chat-data-access';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { customSort, GroupedChats } from '@iese-chatbot/common-utils';

@Component({
  selector: 'icbc-sidebar-chat-history-list',
  standalone: true,
  imports: [CommonModule, SidebarChatHistoryItemComponent],
  templateUrl: './sidebar-chat-history-list.component.html',
})
export class SidebarChatHistoryListComponent {
  protected groupedChats$: Observable<GroupedChats> = inject(Store).select(selectGroupedChats);
  protected readonly customSort = customSort;
  
  constructor(private store: Store) {
  }
}
