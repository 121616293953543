import { format, isToday, isValid, isWithinInterval, isYesterday, parse, subDays } from 'date-fns';
import { KeyValue } from '@angular/common';
import { Chat } from '../model/chat';

// TODO añadir i18n?
const TODAY = 'Today';
const YESTERDAY = 'Yesterday';
const PREVIOUS_7 = 'Previous 7 days';
const PREVIOUS_30 = 'Previous 30 days';

export const isPrevious7Days = (date: Date): boolean => {
  return isWithinInterval(date, {
    start: subDays(new Date(), 7),
    end: new Date(),
  }) && !isToday(date) && !isYesterday(date);
};

export const isPrevious30Days = (date: Date): boolean => {
  return isWithinInterval(date, { start: subDays(new Date(), 30), end: subDays(new Date(), 7) });
};

export const getFormattedDate = (date: Date): string => {
  if (isToday(date)) {
    return TODAY;
  } else if (isYesterday(date)) {
    return YESTERDAY;
  } else if (isPrevious7Days(date)) {
    return PREVIOUS_7;
  } else if (isPrevious30Days(date)) {
    return PREVIOUS_30;
  } else {
    return format(date, 'MMM dd, yyyy');
  }
};

export const customSort = (a: KeyValue<string, Chat[]>, b: KeyValue<string, Chat[]>): number => {
  const predefinedOrder = [TODAY, YESTERDAY, PREVIOUS_7, PREVIOUS_30];
  const orderA = predefinedOrder.indexOf(a.key);
  const orderB = predefinedOrder.indexOf(b.key);

  // If both keys are in the predefined order
  if (orderA !== -1 && orderB !== -1) {
    return orderA - orderB;
  }

  // If one of the keys is in the predefined order
  if (orderA !== -1) {
    return -1;
  }
  if (orderB !== -1) {
    return 1;
  }

  // Parse the dates for non-predefined keys
  const dateA = parse(a.key, 'MMM dd, yyyy', new Date());
  const dateB = parse(b.key, 'MMM dd, yyyy', new Date());

  if (isValid(dateA) && isValid(dateB)) {
    return dateB.getTime() - dateA.getTime();
  }

  return 0; // If dates are not valid, consider them equal
};
