import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormComponent } from '../generic-form/generic-form.component';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { AbstractKeywordsForm } from './abstract-keywords.form';
import { NewChatSuggestionType, NewChatSuggestionTypeDescription } from '@iese-chatbot/common-utils';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';

@Component({
  selector: 'icbc-abstract-keywords',
  standalone: true,
  imports: [CommonModule, FormsModule, InputTextModule, ReactiveFormsModule],
  templateUrl: './abstract-keywords.component.html',
})
export class AbstractKeywordsComponent extends GenericFormComponent {
  constructor(
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
  ) {
    super();
  }
  protected override form: FormGroup = new AbstractKeywordsForm();

  protected override buildPrompt(): string {
    return `
You are the editor of a prestigious business school.
Your task is to provide a clear and engaging summary of ${this.form.value.subject}
that highlight the main points and keeps the audience interested, as well as keywords. 
    `;
  }
  protected submitSuggestionFormEvent() {
    if (this.form.valid) {
      this.monitoringService.trackEventWithProps('ShortCut_Type_Sent', { ShortCut: NewChatSuggestionTypeDescription(NewChatSuggestionType.ABSTRACT_KEYWORDS) });
    }
  }
  
  protected handleKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.submitSuggestionFormEvent();
      this.submitForm();
      this.form
    }
  }

}
