import { FormControl, FormGroup, Validators } from '@angular/forms';

export const DEFAULT_TEACHING_NOTE_CASE_TITLE = '';
export const DEFAULT_TEACHING_NOTE_LEARNING_OBJECTIVE = '';
export const DEFAULT_TEACHING_NOTE_DURATION = 75;
export const DEFAULT_TEACHING_NOTE_INTENDED_AUDIENCE = '';

export type TeachingNoteOptions = {
  caseTitle: string;
  learningObjective: string;
  duration: number;
  intendedAudience: string;
}

export class TeachingNoteForm extends FormGroup {

  constructor(initialValue?: Partial<TeachingNoteOptions>) {
    super({
      caseTitle: new FormControl<string>(
        initialValue?.caseTitle ?? DEFAULT_TEACHING_NOTE_CASE_TITLE, [Validators.required],
      ),
      learningObjective: new FormControl<string>(
        initialValue?.learningObjective ?? DEFAULT_TEACHING_NOTE_LEARNING_OBJECTIVE, [Validators.required],
      ),
      duration: new FormControl<number>(
        initialValue?.duration ?? DEFAULT_TEACHING_NOTE_DURATION, [Validators.min(1), Validators.max(999)],
      ),
      intendedAudience: new FormControl<string>(
        initialValue?.intendedAudience ?? DEFAULT_TEACHING_NOTE_INTENDED_AUDIENCE, [Validators.required],
      ),
    });
  }

}
