import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Shortcut } from '@iese-chatbot/common-utils';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'icbc-new-chat-help',
  standalone: true,
  imports: [CommonModule, DialogModule],
  templateUrl: './new-chat-help.component.html',
})
export class NewChatHelpComponent {
  @Input() public newChatSuggestion: Shortcut | undefined;
  // TODO refactor para abstraer lógica común DIALOG-COMMON
  @Output() public closeHelp: EventEmitter<void> = new EventEmitter<void>();

  protected visible = true;
}
