import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { selectSidebarVisible } from '@iese-chatbot/ui-state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'uids-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    PanelModule,
  ],
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {

  public sidebarCollapsed$: Observable<boolean> = inject(Store).select(selectSidebarVisible);

}
