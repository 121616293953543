import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from 'primeng/toolbar';
import { IeseLogotypeUiComponent } from '../icons/iese-logo/iese-logotype.ui-component';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { IeseLogoUiComponent } from '../icons/iese-logo/iese-logo.ui-component';

@Component({
  selector: 'uids-toolbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,

    ButtonModule,
    ToolbarModule,

    IeseLogoUiComponent,
    IeseLogotypeUiComponent,
  ],
  templateUrl: './toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {}
