import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import {
  SidebarButtonUiComponent,
  SidebarComponent,
  ToolbarComponent,
} from '@iese-chatbot/ui-design-system';
import { ToolbarUserProfileComponent } from '../../components/toolbar-user-profile/toolbar-user-profile.component';
import { ToolbarChatTitleComponent } from '../../components/toolbar-chat-title/toolbar-chat-title.component';
import { ButtonModule } from 'primeng/button';
import { SidebarChatHistoryListComponent } from '../../components/sidebar-chat-history-list/sidebar-chat-history-list.component';
import { MainChatBoxComponent } from '../../components/main-chat-box/main-chat-box.component';
import { SidebarNewChatComponent } from '../../components/sidebar-new-chat/sidebar-new-chat.component';
import { SidebarReleaseNotesComponent } from '../../components/sidebar-release-notes/sidebar-release-notes.component';
import { Store } from '@ngrx/store';
import { selectSidebarVisible, UiActions } from '@iese-chatbot/ui-state';
import { Observable } from 'rxjs';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BdcWalkModule } from 'bdc-walkthrough';
import { BdcWalkService } from 'bdc-walkthrough';
import { MONITORING_SERVICE, MonitoringService } from '@iese-chatbot/azure-insights';

@Component({
  selector: 'icbc-chat-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,

    ButtonModule,
    ConfirmDialogModule,

    ToolbarComponent,
    ToolbarUserProfileComponent,
    ToolbarChatTitleComponent,

    SidebarComponent,
    SidebarNewChatComponent,
    SidebarChatHistoryListComponent,
    SidebarReleaseNotesComponent,

    SidebarButtonUiComponent,
    MainChatBoxComponent,

    BdcWalkModule,
  ],
  templateUrl: './chat-layout.component.html',
})
export class ChatLayoutComponent implements OnInit {
  protected sidebarVisible$: Observable<boolean>;

  constructor(
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService, 
    private store: Store, 
    protected bdcWalkService: BdcWalkService) {
    this.sidebarVisible$ = this.store.select(selectSidebarVisible);
  }
  ngOnInit(): void {
    if (!this.bdcWalkService.getTaskCompleted('dialogStep13')) {
      this.bdcWalkService.reset();
      this.bdcWalkService.changes.subscribe(() => {
        this.bdcWalkService.setTaskCompleted('showTour', true);
      });
    }
  }
  protected sendSupportEventAndMailTo() {
    this.monitoringService.trackEvent('Support_Click');
    window.location.href = 'mailto:myai.support@iese.edu';
  }
  endOnboardingTour() {
    this.bdcWalkService.setTaskCompleted('dialogStep1', true);
    this.bdcWalkService.setTaskCompleted('dialogStep2', true);
    this.bdcWalkService.setTaskCompleted('popupStep3', true);
    this.bdcWalkService.setTaskCompleted('popupStep4', true);
    this.bdcWalkService.setTaskCompleted('popupStep5', true);
    // this.bdcWalkService.setTaskCompleted('popupStep6', true);
    // this.bdcWalkService.setTaskCompleted('popupStep7', true);
    this.bdcWalkService.setTaskCompleted('dialogStep8', true);
    this.bdcWalkService.setTaskCompleted('popupStep9', true);
    // this.bdcWalkService.setTaskCompleted('popupStep10', true);
    this.bdcWalkService.setTaskCompleted('popupStep11', true);
    this.bdcWalkService.setTaskCompleted('dialogStep12', true);
    this.bdcWalkService.setTaskCompleted('dialogStep13', true);
  }
  toggleSidebar() {
    this.store.dispatch(UiActions.sidebarToggle());
  }
}
