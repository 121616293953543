import { ErrorHandler, Provider } from '@angular/core';
import { AZURE_INSIGHTS_CONFIG, MONITORING_SERVICE } from '../config';
import { AzureInsightsService } from '../services/azure-insights.service';
import { AzureInsightsErrorHandler } from '../error-handlers/azure-insights.error-handler';
import { MonitoringMockService } from '../services/monitoring-mock.service';

export const provideMonitoring = (instrumentationKey: string | null): Provider[] => {
  if (instrumentationKey) {
    return [
      { provide: AZURE_INSIGHTS_CONFIG, useValue: { instrumentationKey } },
      { provide: MONITORING_SERVICE, useClass: AzureInsightsService },
      { provide: ErrorHandler, useClass: AzureInsightsErrorHandler },
    ];
  } else {
    return [
      { provide: MONITORING_SERVICE, useClass: MonitoringMockService },
    ];
  }
};
