<form class="p-fluid" [formGroup]="form" (ngSubmit)="submitSuggestionFormEvent(); submitForm()">

  <div class="form-field form-row">
    <label class="form-label" for="intended-audience">Intended audience</label>
    <input class="p-inputtext form-input" id="intended-audience" type="text" pInputText
           formControlName="intendedAudience" />
  </div>

  <div class="form-field form-row">
    <label class="form-label" for="course-topic">Course topic</label>
    <input class="p-inputtext form-input" id="course-topic" type="text" pInputText
           formControlName="courseTopic" />
  </div>

  <div class="form-field form-row">
    <label class="form-label" for="amount-sessions">Amount of sessions</label>
    <input class="p-inputtext form-input" id="amount-sessions" type="number" pInputText
           formControlName="amountSessions" (keydown)="handleKeyup($event)" />
  </div>

  <div class="form-field form-row">
    <label class="form-label" for="learning-objectives">Learning objectives</label>
    <input class="p-inputtext form-input" id="learning-objectives" type="text" pInputText
           formControlName="learningObjectives" (keydown)="handleKeyup($event)" />
  </div>

  <div class="form-actions">
    <button class="iese-button" (click)="close()">
      <span>Cancel</span>
    </button>
    <button class="iese-button primary" type="reset">
      <i class="pi pi-delete-left"></i>
      <span>Clear</span>
    </button>
    <button class="iese-button primary" type="submit" [ngClass]="{ 'disabled': !form.valid}">
      <i class="pi pi-send"></i>
      <span>Send</span>
    </button>
  </div>

</form>
