import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { MonitoringService } from '../services/monitoring.service';
import { MONITORING_SERVICE } from '../config';

@Injectable()
export class AzureInsightsErrorHandler implements ErrorHandler {

  constructor(@Inject(MONITORING_SERVICE) private monitoringService: MonitoringService) {}

  handleError(error: any): void {
    this.monitoringService.trackTrace(error.message || 'Error');
    console.error(error.stack);
  }

}
