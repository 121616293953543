<p-dialog *ngIf="citation" [modal]="true" [closeOnEscape]="true" [dismissableMask]="true" [(visible)]="visible"
  (onHide)="closeCitation.emit()" [style]="{ width: '50rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
  <!-- HEADER -->
  <ng-template pTemplate="header">
    <div>
      <strong>{{ citation.title }}</strong>
      <span *ngIf="documentCategory"> - {{ documentCategory }}</span>
      <span class="dim" *ngIf="uploadDate"> - {{ uploadDate }}</span>
    </div>
  </ng-template>

  <!-- CONTENT -->
  <ng-template pTemplate="content">
    <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
      <p-accordionTab header="Extract" iconPos="end">
        <div class="dialog-content" [innerHTML]="citation.content | mdParse"></div>
      </p-accordionTab>
    </p-accordion>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="footer" *ngIf="detailLink && detailLink !== ''">
    <div class="citation-metadata-list">
      <div class="citation-metadata-item">
        <strong>Details</strong>
        <span>
          <a class="iese-rounded-button" [href]="detailLink" target="_blank">
            <i class="pi pi-eye"></i>
          </a>
        </span>
      </div>
      <div class="citation-metadata-item">
        <strong>Download</strong>
        <span>
          <a class="iese-rounded-button" [href]="downloadLink" [download]="citation.filepath">
            <i class="pi pi-download"></i>
          </a>
        </span>
      </div>
    </div>
  </ng-template>
</p-dialog>