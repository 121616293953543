<form class="p-fluid" [formGroup]="form" (ngSubmit)="submitSuggestionFormEvent(); submitForm()">

  <div class="form-field form-row">
    <label class="form-label" for="subject">Subject</label>
    <input class="p-inputtext form-input" id="subject" type="text" pInputText
           formControlName="subject" (keydown)="handleKeyup($event)" />
  </div>

  <div class="form-actions">
    <button class="iese-button" (click)="close()">
      <span>Cancel</span>
    </button>
    <button class="iese-button primary" type="reset">
      <i class="pi pi-delete-left"></i>
      <span>Clear</span>
    </button>
    <button class="iese-button primary" type="submit" [ngClass]="{ 'disabled': !form.valid}">
      <i class="pi pi-send"></i>
      <span>Send</span>
    </button>
  </div>

</form>
