// noinspection JSUnusedGlobalSymbols

import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MONITORING_SERVICE, MonitoringActions, MonitoringService } from '@iese-chatbot/azure-insights';
import { AuthActions } from '../actions/auth.actions';
import { map, tap } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class AuthEffects {

  initializeAuth$ = createEffect(() => this.actions$.pipe(
    ofType('@ngrx/effects/init'),
    tap(() => this.authService.initialize()),
  ), { dispatch: false });

  tokenAcquired$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.storeAuthData),
      map(action => {
        return MonitoringActions.setContext({ user: action.account });
      }),
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(async () => {
        await this.authService.logout();
        this.monitoringService.trackEvent('[LOGOUT]');
        this.monitoringService.setContext(null);
      }),
    );
  }, { dispatch: false });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    @Inject(MONITORING_SERVICE) private monitoringService: MonitoringService,
  ) {}

}
